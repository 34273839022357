import { Section, SectionTitle } from '../../components/styled/section'
import { Column1, GridWithGap } from '../../components/styled/grid'
import React from 'react'

import fallbackImg from '../../images/bilxtra.png'
import elbilLogo from '../../images/EL_BIL merke.png'
import { OpeningHours } from './OpeningHours'

import styled from 'styled-components'

const OpentingHouserStyl = styled(Column1)`
  margin: auto;
`
const TexBoxSty = styled.div`
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 95px;
  margin-right: -25px;
`

const Images = styled.img`
  width: 90%;
  margin: auto;
  height: auto;

  @media (max-width: 1023px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`

const HolderAbout = styled.div`
  max-width: 75%;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin: auto;
`
const HolderContentImage = styled.div`
  @media (max-width: 1023px) {
    margin: auto;
  }
`
const HolderTORow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
`
const AboutSection = ({ workshop, allImages }) => {
  const isElApproved =
    workshop.settings.filter((s) => s.key === 'EL_APPROVED' && s.value === 'true').length > 0

  const images = allImages.filter(({ node }) => node.workshop_id === workshop.workshop_id)

  var nr1 = ''
  var nr2 = ''

  if (images.length >= 2) {
    const [nr1image, nr2image] = images
    nr1 = nr1image.node.image_url
    nr2 = nr2image.node.image_url
  }

  return (
    <div id="about">
      <br></br>
      <Section
        style={{
          position: 'relative',
        }}
      >
        <SectionTitle>Om {workshop.name}</SectionTitle>
        <GridWithGap>
          <Column1>
            <GridWithGap>
              <HolderTORow>
                <HolderContentImage>
                  <img
                    src={nr1}
                    alt="bilde-1"
                    style={{
                      width: '400px',
                      border: '5px solid #1A51A0',
                      height: '300px',
                    }}
                  ></img>
                </HolderContentImage>

                <HolderAbout>
                  {isElApproved === true ? (
                    <Images src={elbilLogo} />
                  ) : (
                    <Images src={fallbackImg} />
                  )}
                </HolderAbout>
                <HolderContentImage>
                  <img
                    src={nr2}
                    alt={`Bilde 1 av  ${workshop.name}`}
                    style={{
                      width: '400px',
                      border: '5px solid #1A51A0',
                      height: '300px',
                    }}
                  ></img>
                </HolderContentImage>
              </HolderTORow>
            </GridWithGap>
            <GridWithGap>
              <Column1>
                <div dangerouslySetInnerHTML={{ __html: workshop.free_text }} />
              </Column1>
            </GridWithGap>
            <GridWithGap>
              <TexBoxSty>
                {workshop.street && (
                  <React.Fragment>
                    <h4>Adresse</h4>
                    <b>{workshop.name}</b>
                    <p>
                      {workshop.street}
                      {workshop.housenr}
                      <br />
                      {workshop.zip} {workshop.city}
                    </p>
                  </React.Fragment>
                )}
                {workshop.phone && (
                  <p>
                    Telefon: <a href={`tel:${workshop.phone}`}> {workshop.phone}</a>
                  </p>
                )}
                {workshop.email && (
                  <p>
                    E-post: <a href={`mailto:${workshop.email}`}> {workshop.email}</a>
                  </p>
                )}
                {workshop.homepage && (
                  <p>
                    <a href={workshop.homepage} target="_blank" rel="noreferrer">
                      Besøk vår hjemmeside
                    </a>
                  </p>
                )}
                {workshop.facebook && (
                  <p>
                    <a href={workshop.facebook} target="_blank" rel="noreferrer">
                      Besøk vår facebook
                    </a>
                  </p>
                )}
                {workshop.instagram_url && (
                  <p>
                    <a href={workshop.instagram_url} target="_blank" rel="noreferrer">
                      Besøk vår instagram
                    </a>
                  </p>
                )}
              </TexBoxSty>
              <OpentingHouserStyl>
                {workshop.opening_hours && <OpeningHours specification={workshop.opening_hours} />}
              </OpentingHouserStyl>
            </GridWithGap>
          </Column1>
        </GridWithGap>
      </Section>
    </div>
  )
}
export default AboutSection
