import { Column1, GridWithGap } from '../../components/styled/grid'
import { H3 } from '../../components/styled/h4'
import { Section, SectionParagraph } from '../../components/styled/section'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

const MobilitetsGarantiSection = ({ image }) => {
  return (
    <Section>
      <GridWithGap>
        <Column1>
          <H3>Service med mobilitetsgaranti</H3>
          <SectionParagraph>
            Hos BilXtra verksted får du 12 måneders Mobilitetsgaranti med på kjøpet, hvis du
            bestiller BilXtra – service med Mobilitetsgaranti, eller service ut fra bilens
            servicehefte.
          </SectionParagraph>
          <SectionParagraph>
            Med BilXtra Mobilitetsgaranti får du hjelp uansett tid og sted i hele Norden i et helt
            år. Ved å utføre service hos BilXtra verksted, vil Mobilitetsgarantien fornyes med 12
            måneder og gi deg ekstra trygghet.
          </SectionParagraph>
          <Link to="/mulighet-bilxtra/mobilitetsgaranti" className="link">
            Les mer om mobilitetsgaranti
          </Link>
        </Column1>
        <Column1>
          <Img fluid={image} />
        </Column1>
      </GridWithGap>
    </Section>
  )
}

export default MobilitetsGarantiSection
