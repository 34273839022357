import React, { useState } from 'react'
import PropTypes from 'prop-types'
import WorkshopHeader from '../components/workshopHeader'
import './layout.css'
import HeroWorkplae from '../components/heroWorkplae'
import HeroOptionsWorkshop from '../components/heroOptionsWorkshop'
import NextPkkHeroOptionWorkshop from '../components/nextPkkHeroOptionWorkshop'

import Footer from '../components/footer'
import { graphql, Link, useStaticQuery, navigate } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { device } from '../components/device'
import { useSetFavoriteWorkshop } from '../hooks/use-favorite-workshop'
import { Layout } from '../components/styled/layout'
import { generateWorkshopPagePrefix } from '../url-helpers'
import ScrollTop from '../components/scrollTop'
import SelectVideoModel from '../components/selectVideoModel'

import Booking from '../pages/booking'

const GoToOrder = styled.div`
  flex: 1;
  margin-top: -10px;
  max-width: 500px;
  min-width: 300px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  background: var(--primary-color);

  text-align: center;

  a {
    padding: 0.8rem;
    display: block;
    color: white;
    font-weight: 700;
    text-decoration: none;
    font-size: 0.9rem;
  }
`

const Icon = styled.div`
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
`
const HeroBoxHolder = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  margin-top: 14px;
  justify-content: center;
  @media (max-width: 1441px) {
    margin-top: 6px;
  }

  @media (max-width: 1025px) {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 25px;
  }
  @media (max-width: 1023px) {
    margin-top: 1px;
  }
`
const HeroOrderWorkplase = styled.div`
  margin: auto;
  margin-top: 25px;
  margin-bottom: -3rem;
  text-align: center;
  display: grid;
  grid-template-columns: 2fr 1fr;
  max-width: 1200px;

  @media (max-width: 1441px) {
    grid-template-columns: auto;
  }
  @media (max-width: 1023px) {
    margin-top: 1px;
  }
`
export const SectionTitleELementes = styled.h1`
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 700;
  color: #333333;
  text-align: right;
  @media only screen and ${device.tabletMaxL} {
    font-size: 3em;
    text-align: center;
  }
  @media (max-width: 1441px) {
    font-size: 3em;
    text-align: center;
  }
`

const WorkshopLayout = ({ children, workshop }) => {
  const [showWorkshopSelector, setShowWorkshopSelector] = useState(false)
  //   const url = typeof window !== 'undefined' ? window.location : ''
  //   let location = url?.hash

  useSetFavoriteWorkshop(workshop)
  const data = useStaticQuery(graphql`
    query {
      clock: file(relativePath: { eq: "clock.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const prefix = generateWorkshopPagePrefix(workshop)

  return (
    <Layout>
      {showWorkshopSelector && <SelectVideoModel onClose={() => setShowWorkshopSelector(false)} />}

      <WorkshopHeader
        affiliationName={workshop.affiliation_name}
        siteTitle={workshop.name}
        slug={workshop.slug}
      />
      <HeroWorkplae workshop={workshop} />
      <HeroOptionsWorkshop>
        <HeroBoxHolder>
          <GoToOrder>
            <Link to="#Bestilltime" style={{ width: '500px' }}>
              <Icon>
                <Img fluid={data.clock.childImageSharp.fluid} />
              </Icon>
              Bestill time
            </Link>
          </GoToOrder>

          <NextPkkHeroOptionWorkshop
            orderButtonClicked={async () => {
              await navigate(`/${prefix}/${workshop.slug}#Bestilltime`)
            }}
          />
        </HeroBoxHolder>
      </HeroOptionsWorkshop>

      <div id="Bestilltime"></div>
      <br />
      <HeroOrderWorkplase className="bworkshop">
        <Booking workshop={workshop} />
      </HeroOrderWorkplase>
      <main>{children}</main>
      <ScrollTop />
      <Footer />
    </Layout>
  )
}

WorkshopLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WorkshopLayout
