import { Section } from '../../components/styled/section'
import React from 'react'
import styled from 'styled-components'

const Video = styled.video`
  width: 100%;
  height: auto;
`

const VideoSection = () => {
  return (
    <Section>
      <Video src="https://nodelab.no/bilxtra/oljeskift_m_tekst_1.mp4" controls autoPlay muted />
    </Section>
  )
}

export default VideoSection
