import { Section, SectionParagraph } from '../../components/styled/section'
import { Column1, Column3, Grid } from '../../components/styled/grid'
import { H3 } from '../../components/styled/h4'
import { OutlinedButtonLink, PrimaryButtonLink } from '../../components/styled/buttonLinks'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import React from 'react'
import SectionOverlay from '../../components/styled/section-overlay'
import styled from 'styled-components'

const Holder = styled.div`
  margin-bottom: -8rem;
`
const HolderButtonbottom = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  margin-top: 5rem;
  @media (max-width: 1024px) {
    width: 100%;
    grid-template-columns: 1fr;
  }
`
const HolderMainContetent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1024px) {
    width: 100%;
    grid-template-columns: 1fr;
  }
`
const BilXtraKontoSection = ({ backgroundImage, image }) => {
  return (
    <Holder>
      <BackgroundImage fluid={backgroundImage}>
        <SectionOverlay>
          <Section>
            <Grid>
              <HolderMainContetent>
                <Column1>
                  <H3>BilXtra Konto – del opp betalingen!</H3>
                  <SectionParagraph>
                    BilXtrakortet gir deg som forbruker 30-60 dager betalingsutsettelse helt uten
                    ekstra kostnad. Velger du å dele opp betalingen fra 4-12 måneder, kommer det
                    ingen renter i tillegg. Kan brukes i både butikk og verksted!
                  </SectionParagraph>
                  <SectionParagraph>
                    Utsett betalingen – søk om BilXtrakortet i nærmeste BilXtra bilverksted/butikk.
                  </SectionParagraph>
                  <HolderButtonbottom>
                    <Column3>
                      <OutlinedButtonLink>
                        <a href="https://bilxtra.no/bilxtra-konto-brosjyre/">Last ned brosjyre</a>
                      </OutlinedButtonLink>
                    </Column3>

                    <Column3>
                      <PrimaryButtonLink>
                        <a href="https://apponline.resurs.com/form/44000">Søk nå</a>
                      </PrimaryButtonLink>
                    </Column3>
                  </HolderButtonbottom>
                </Column1>
                <Column1>
                  <Img fluid={image} />
                </Column1>
              </HolderMainContetent>
            </Grid>
          </Section>
        </SectionOverlay>
      </BackgroundImage>
    </Holder>
  )
}

export default BilXtraKontoSection
