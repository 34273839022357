import React from 'react'
import styled from 'styled-components'

const PrimaryBox = styled.a`
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 1rem 2rem;
  height: 250px;
  text-align: center;
  color: white;
  background: var(--primary-color);

  &:link,
  &:visited {
    color: white;
    text-decoration: none;
  }

  h4 {
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0;
  }
`

const BlueBox = ({ title, description, to }) => {
  return (
    <PrimaryBox href={to} target="_blank">
      <h4>{title}</h4>
      <p>{description}</p>
    </PrimaryBox>
  )
}

export default BlueBox
