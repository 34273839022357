import React from 'react'
import styled from 'styled-components'
import { device } from '../device'
import tutorialvideo from '../../images/video/Hvordan-bestille-time.mp4'
const Video = styled.video`
  width: 90%;
  height: auto;
  min-height: 10%;
  min-width: 10%;
  border-radius: 5px;
  margin: auto;

  @media (max-width: 1024px) {
    //display: none;
  }
  @media (max-width: 1444px) {
  }

  @media only screen and ${device.tablet} {
    //margin-left: -25px;
    //margin-top: 4.5rem;
  }
`
const VideoBox = () => {
  return <Video src={tutorialvideo} controls={true} autoPlay={true} muted={true} />
}
export default VideoBox
