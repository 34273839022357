import React from 'react'
import Video from '../components/small_web_comp/Video'
import styled from 'styled-components'
import { device } from './device'
const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(0, 0, 0, 0.6);
`

const ModalBody = styled.div`
  position: relative;
  padding: 2rem;
  //top: 10vh;
  left: 50%;
  transform: translateX(-50%);
  max-width: 900px;
  border-radius: 2px;
  background: transparent;
  overflow-x: hidden;

  @media ${device.tablet} {
    //max-width: 100%;
    overflow-x: hidden; //horizontal
    overflow-y: hidden; //vertical
  }
  @media ${device.tabletMaxL} {
    //width: 100%;
    overflow-x: hidden; //horizontal
    overflow-y: hidden; //vertical
  }
`
const ModalCloseButton = styled.button`
  position: absolute;
  border-radius: 2px;
  top: 10%;
  right: 6rem;
  margin: auto;
  font-size: inherit;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #ffffff;
  color: #1a51a0;
`
const ModalContent = styled.div`
  overflow: hidden;
  margin: auto;
  margin-top: 5rem;
  min-width: 10%;
  min-height: 10%;
`

const SelectWorkshopModal = ({ onClose }) => {
  return (
    <ModalOverlay>
      <ModalBody>
        <ModalCloseButton type="button" onClick={onClose}>
          Lukk
        </ModalCloseButton>

        <ModalContent>
          <Video />
        </ModalContent>
      </ModalBody>
    </ModalOverlay>
  )
}

export default SelectWorkshopModal
