import styled from 'styled-components'

export const OutlinedButtonLink = styled.div`
  a {
    padding: 1rem;
    color: white;
    display: block;
    font-weight: 700;
    text-align: center;
    border: 2px solid white;
    text-decoration: none;
    transition: all 0.25s ease-out;

    &:hover {
      color: var(--primary-color);
      background: white;
    }
  }
`

export const PrimaryButtonLink = styled.div`
  a {
    padding: 1rem;
    color: white;
    display: block;
    font-weight: 700;
    text-align: center;
    border: 2px solid var(--primary-color);
    text-decoration: none;
    transition: all 0.25s ease-out;
    background: var(--primary-color);
  }
`
