import { Column1, GridWithGap } from '../../components/styled/grid'
import { H3 } from '../../components/styled/h4'
import { Section, SectionParagraph } from '../../components/styled/section'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

const BileiersValgmuligheterSection = ({ image }) => {
  return (
    <Section>
      <GridWithGap>
        <Column1>
          <H3>Bileiers valgmuligheter</H3>
          <SectionParagraph>
            Det er visse vilkår som bileier må følge for å opprettholde garantier. Disse fastsettes
            av bilprodusenten eller importøren for garantier som gis ved nybilsalg.
            Konkurransereglene er til hinder for at slike garantivilkår fratar bileier retten til å
            velge verksted og reservedeler når han selv betaler.
          </SectionParagraph>
          <Link to="/mulighet-bilxtra/Fritt-verkstedvalg" className="link">
            Les mer om dine valgmuligheter
          </Link>
        </Column1>
        <Column1>
          <Img fluid={image} />
        </Column1>
      </GridWithGap>
    </Section>
  )
}

export default BileiersValgmuligheterSection
