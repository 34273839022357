import { useState } from 'react'

export const useVehicleByRegNo = () => {
  const [searching, setSearching] = useState(false)
  const [error, setError] = useState('')

  const search = (regNo) => {
    if (regNo) {
      setSearching(true)

      return fetch(`${process.env.API_URL}vehicle/${regNo}`).then(async (response) => {
        setSearching(false)
        const json = await response.json()

        if (!response.ok) {
          setError(json.message)
        }

        return json
      })
    }
  }

  return {
    searching,
    search,
    error,
  }
}
