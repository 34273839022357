import styled from 'styled-components'

export const H4 = styled.h4`
  margin: 0;
  text-align: center;

  & ~ p {
    text-align: center;
  }
`

export const H3 = styled.h3`
  font-weight: 700;
`
