import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { device } from './device'
import { Column1, GridWithGap } from '../components/styled/grid'

import fallbackImg from '../images/bilxtra.png'
import elbilLogo from '../images/EL_BIL merke.png'

const HolderTORow = styled.div`
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
`
const HolderContentImage = styled.div`
  @media (max-width: 1023px) {
    margin: auto;
  }
  img {
    width: 400px;
    height: 300px;
    border: 5px solid #1a51a0;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      padding: 0 60px;
      border: none;
      height: auto;
    }
  }
`
const SideImagesStyled = styled.div`
  height: 70vh;
  padding-top: 10rem;
  @media ${device.tabletMaxL} {
    display: none;
  }
  div {
    margin: 10px 0;
    div {
      display: flex;
      justify-items: center;
      height: 300px;
      justify-content: center;
    }
  }
`
const Images = styled.img`
  @media (max-width: 1023px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`

const HeroWorkplae = ({ children, workshop }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allWorkshopImage {
        edges {
          node {
            image_url
            workshop_id
          }
        }
      }
    }
  `)

  const isElApproved =
    workshop.settings.filter((s) => s.key === 'EL_APPROVED' && s.value === 'true').length > 0
  const images = data.images.edges.filter(({ node }) => node.workshop_id === workshop.workshop_id)

  let nr1 = ''
  let nr2 = ''

  if (images.length >= 2) {
    const [nr1image, nr2image] = images
    nr1 = nr1image.node.image_url
    nr2 = nr2image.node.image_url
  }

  return (
    <SideImagesStyled>
      <GridWithGap>
        <Column1>
          <HolderTORow>
            <HolderContentImage>
              <img src={nr1} alt="bilde-1"></img>
              <div className="logo">
                {isElApproved === true ? <Images src={elbilLogo} /> : <Images src={fallbackImg} />}
              </div>
              <img src={nr2} alt={`Bilde 1 av  ${workshop.name}`}></img>
            </HolderContentImage>
          </HolderTORow>
        </Column1>
      </GridWithGap>
    </SideImagesStyled>
  )
}

export default HeroWorkplae
