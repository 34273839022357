import React from 'react'
import nr4 from '../../images/bigpopupimg/bilxtrahjelpstor4.png'
import styled from 'styled-components'
import { device } from '../device'
const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(0, 0, 0, 0.6);
`

const ModalBody = styled.div`
  //position: relative;
  margin-top: 0rem;
  padding: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  max-width: 100%;

  border-radius: 2px;
  background: transparent;
  overflow-x: hidden;

  @media ${device.tablet} {
    //max-width: 100%;
    overflow-x: hidden; //horizontal
    overflow-y: hidden; //vertical
  }
  @media ${device.tabletMaxL} {
    width: 100%;
    overflow-x: hidden; //horizontal
    overflow-y: hidden; //vertical
  }
  img {
    height: 45%;
  }
`

const ModalHeader = styled.div`
  position: relative;
  margin-bottom: -2rem;
  margin-right: 1rem;
`

const ModalCloseButton = styled.button`
  position: absolute;
  border-radius: 2px;
  top: 5%;

  right: -20px;
  //right: 95%;
  margin: auto;
  font-size: inherit;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #ffffff;
  color: #1a51a0;
`

const ModalContent = styled.div`
  overflow: hidden;
  margin: auto;
  margin-top: 5rem;
`
const Nr3Popup = ({ onClose }) => {
  return (
    <ModalOverlay>
      <ModalBody>
        <ModalHeader>
          <ModalCloseButton type="button" onClick={onClose}>
            Lukk
          </ModalCloseButton>
        </ModalHeader>
        <ModalContent>
          <img
            src={nr4}
            style={{ borderRadius: '10px', width: '100%' }}
            alt="forklarings bilde nr 3"
          ></img>
        </ModalContent>
      </ModalBody>
    </ModalOverlay>
  )
}

export default Nr3Popup
