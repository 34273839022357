import React from 'react'

const daysMap = {
  Monday: 'Mandag',
  Tuesday: 'Tirsdag',
  Wednesday: 'Onsdag',
  Thursday: 'Torsdag',
  Friday: 'Fredag',
  Saturday: 'Lørdag',
  Sunday: 'Søndag',
}

const weekdaysInOrder = ['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag', 'Søndag']
const orderWeekdays = (a, b) => weekdaysInOrder.indexOf(a.name) - weekdaysInOrder.indexOf(b.name)

const parseDay = (line) =>
  line.dayOfWeek.flatMap((day) => ({
    name: daysMap[day],
    opens: line.opens,
    closes: line.closes,
  }))
export const OpeningHours = ({ specification: { openingHoursSpecification } }) => {
  const openingHoursByDay = openingHoursSpecification.flatMap(parseDay).sort(orderWeekdays)
  return (
    <table style={{ padding: '10px', margin: 'auto' }}>
      <h4 style={{ borderBottom: '1px solid black' }}>Åpningstider</h4>
      {openingHoursByDay.map((day) => (
        <tr>
          <td>{day.name}</td>
          <td>{day.opens} -</td>
          <td>{day.closes}</td>
        </tr>
      ))}
    </table>
  )
}
