import React, { useEffect, useRef, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import WorkshopLayout from '../layouts/workshopLayout'
import SEO from '../components/seo'
import BilXtraKontoSection from './sections/bilxtra-konto'
import ReservedelerSection from './sections/reservedeler'
import MobilitetsGarantiSection from './sections/mobilitetsgaranti'
import BileiersValgmuligheterSection from './sections/bileiers-valgmuligheter'
import AboutSection from './sections/about'
import BlueBoxesSection from './sections/blue-boxes'
import WebOrderSection from './sections/web-order'
import VideoSection from './sections/video'
import scrollTo from '../scroll-to'
import TextBoks from '../components/small_web_comp/TextBoks'
import { useLocation } from '@reach/router'
import Nr1Popup from '../components/pup-upfils/nr1PopUp'
import Nr2Popup from '../components/pup-upfils/nr2PopUp'
import Nr3Popup from '../components/pup-upfils/nr3PopUp'
import { Layout } from '../components/styled/layout'
const WorkshopPageTemplate = ({ pageContext: { workshop } }) => {
  const location = useLocation()
  const webOrderSection = useRef(null)
  const bilxtraKontoSection = useRef(null)
  const aboutSection = useRef(null)
  const sections = {
    '#ordre': webOrderSection,
    '#about': aboutSection,
    '#bilxtraKonto': bilxtraKontoSection,
  }

  useEffect(() => {
    if (location.hash && sections[location.hash]) {
      scrollTo(sections[location.hash])
    }
  }, [location.hash, sections])

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "bilxtra-banner-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      car: file(relativePath: { eq: "car.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eu: file(relativePath: { eq: "eu-kontroll.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      deler: file(relativePath: { eq: "slitedeler.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobgar: file(relativePath: { eq: "mobilitetsgaranti.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      verksted: file(relativePath: { eq: "verksted_panorama.png" }) {
        childImageSharp {
          fluid(maxWidth: 1380) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bilxtrakort: file(relativePath: { eq: "bilxtra_kortet.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      images: allWorkshopImage {
        edges {
          node {
            image_url
            workshop_id
          }
        }
      }
    }
  `)

  const seoTitle = `${workshop.name} – ${
    workshop.meta_title ? `${workshop.meta_title} | ` : ''
  }Bilverksted & service`
  const [showNr1, SetShownr1] = useState(false)
  const [showNr2, SetShownr2] = useState(false)
  const [showNr3, SetShownr3] = useState(false)

  return (
    <Layout>
      {showNr1 && <Nr1Popup onClose={() => SetShownr1(false)} />}
      {showNr2 && <Nr2Popup onClose={() => SetShownr2(false)} />}
      {showNr3 && <Nr3Popup onClose={() => SetShownr3(false)} />}

      <WorkshopLayout workshop={workshop}>
        <SEO title={seoTitle} />

        <div ref={webOrderSection}>
          <br></br>
          <WebOrderSection workshop={workshop} />
        </div>

        <BlueBoxesSection />

        <VideoSection />

        <BileiersValgmuligheterSection image={data.car.childImageSharp.fluid} />

        <TextBoks></TextBoks>

        {/*
      <EuKontrollSection
        affiliationName={workshop.affiliation_name}
        slug={workshop.slug}
        image={data.eu.childImageSharp.fluid}
      />* */}

        <ReservedelerSection image={data.deler.childImageSharp.fluid} />

        <MobilitetsGarantiSection image={data.mobgar.childImageSharp.fluid} />

        <div ref={aboutSection}>
          <AboutSection workshop={workshop} allImages={data.images.edges} />
        </div>

        <div ref={bilxtraKontoSection}>
          <BilXtraKontoSection
            backgroundImage={data.verksted.childImageSharp.fluid}
            image={data.bilxtrakort.childImageSharp.fluid}
          />
        </div>
      </WorkshopLayout>
    </Layout>
  )
}

export default WorkshopPageTemplate
