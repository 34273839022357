import { Column1, GridWithGap } from '../../components/styled/grid'
import { H3 } from '../../components/styled/h4'
import { Section, SectionParagraph } from '../../components/styled/section'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

const ReservedelerSection = ({ image }) => {
  return (
    <Section>
      <GridWithGap>
        <Column1>
          <H3>5 års garanti på alle reservedeler</H3>
          <SectionParagraph>
            Som et element til kundenes trygghet i valg av verksted, innfører BilXtra 5 års
            garanti/100 000 km på reservedeler som benyttes ved våre verksteder.
          </SectionParagraph>
          <SectionParagraph>
            Garantien er gjeldene fra 01.01.2011 og forutsetter at bilen følger bilfabrikantens
            service - og vedlikeholdsprogram hos BilXtra eller andre offentlig godkjente verksteder.
          </SectionParagraph>
          <Link to="/mulighet-bilxtra/delegaranti" className="link">
            Les mer om delegaranti
          </Link>
        </Column1>
        <Column1>
          <Img fluid={image} />
        </Column1>
      </GridWithGap>
    </Section>
  )
}

export default ReservedelerSection
