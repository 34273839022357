import { Column1, Column2, GridWithGap } from '../../components/styled/grid'
import BlueBox from '../../components/blueBox'
import { Section } from '../../components/styled/section'
import React from 'react'

const BlueBoxesSection = () => {
  return (
    <Section>
      <GridWithGap>
        <Column1 />
        <Column2>
          <BlueBox
            to="http://bilxtra.no/rad-og-tips/"
            title="Biltips"
            description="Vi har tipsene som gjør bilen din klar for sesongen."
          />
        </Column2>
        <Column1 />
      </GridWithGap>
    </Section>
  )
}

export default BlueBoxesSection
