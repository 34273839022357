import { Section } from '../../components/styled/section'
import React from 'react'

const WebOrderSection = ({ workshop }) => {
  return (
    <div>
      <Section>
        {workshop.phone && workshop.email && (
          <p style={{ textAlign: 'center', marginTop: '-2rem', marginBottom: '2rem' }}>
            Kontakt oss på tlf.{' '}
            <a className="link" href={`tel:${workshop.phone}`}>
              {workshop.phone}
            </a>{' '}
            eller{' '}
            <a className="link" href={`mailto:${workshop.email}`}>
              {workshop.email}
            </a>{' '}
            eller bruk skjemaet over.
          </p>
        )}
      </Section>
    </div>
  )
}

export default WebOrderSection
